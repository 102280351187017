// src/App.js

import React, { useState } from 'react';
import axios from 'axios';

const apiEndpoint = 'https://94fv9s1dxj.execute-api.ap-south-1.amazonaws.com';

const App = () => {
  const [userId, setUserId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [userDetails, setUserDetails] = useState(null);

  const handleCreateUser = async () => {
    try {
      const response = await axios.post(`${apiEndpoint}/create`, { userId, name, email });
      setResponseMessage(response.data.message);
      setUserId(''); setName(''); setEmail('');
    } catch (error) {
      setResponseMessage(error.response?.data?.message || 'Error creating user');
    }
  };

  const handleGetUser = async () => {
    try {
      const response = await axios.get(`${apiEndpoint}/get/${userId}`);
      console.log(response);
      setUserDetails(response.data.user);
    } catch (error) {
      setResponseMessage(error.response?.data?.message || 'Error fetching user');
    }
  };

  const handleUpdateUser = async () => {
    try {
      const response = await axios.post(`${apiEndpoint}/update`, { userId, name, email });
      setResponseMessage(response.data.message);
      setUserDetails(response.data.updatedUser);
    } catch (error) {
      setResponseMessage(error.response?.data?.message || 'Error updating user');
    }
  };

  const handleDeleteUser = async () => {
    try {
      const response = await axios.delete(`${apiEndpoint}/delete/${userId}`);
      setResponseMessage(response.data.message);
      setUserDetails(null);
    } catch (error) {
      setResponseMessage(error.response?.data?.message || 'Error deleting user');
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">User Management</h1>
      
      <div className="mb-6">
        <input
          type="text"
          placeholder="User ID"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          className="block w-full p-2 border border-gray-300 rounded mb-2"
        />
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="block w-full p-2 border border-gray-300 rounded mb-2"
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="block w-full p-2 border border-gray-300 rounded mb-2"
        />
      </div>

      <div className="flex space-x-4 mb-6">
        <button onClick={handleCreateUser} className="px-4 py-2 bg-blue-500 text-white rounded">Create User</button>
        <button onClick={handleGetUser} className="px-4 py-2 bg-green-500 text-white rounded">Get User</button>
        <button onClick={handleUpdateUser} className="px-4 py-2 bg-yellow-500 text-white rounded">Update User</button>
        <button onClick={handleDeleteUser} className="px-4 py-2 bg-red-500 text-white rounded">Delete User</button>
      </div>

      {responseMessage && (
        <p className="text-lg text-gray-700 font-semibold">{responseMessage}</p>
      )}

      {userDetails && (
        <div className="mt-4 p-4 border border-gray-300 rounded">
          <h2 className="text-xl font-bold">User Details</h2>
          <p><strong>User ID:</strong> {userDetails.userId}</p>
          <p><strong>Name:</strong> {userDetails.name}</p>
          <p><strong>Email:</strong> {userDetails.email}</p>
        </div>
      )}
    </div>
  );
};

export default App;
